@mixin default-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsl(26, 23%, 24%);
  --m-accent_bg--mix-12: hsl(25, 26%, 24%);
  --m-accent_bg--mix-15: hsl(25, 31%, 25%);
  --m-accent_bg--mix-20: hsl(24, 37%, 26%);
  --m-accent_bg--mix-60: hsl(23, 76%, 34%);
  --m-accent_bg--mix-70: hsl(22, 83%, 36%);
  --m-accent_bg-popup--mix-10: hsl(26, 23%, 24%);
  --m-accent_bg-zebra--mix-10: hsl(26, 23%, 24%);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsl(12, 18%, 25%);
  --m-bad_bg--mix-15: hsl(9, 22%, 26%);
  --m-bad_bg--mix-20: hsl(6, 26%, 28%);
  --m-bad_bg--mix-25: hsl(5, 30%, 29%);
  --m-bad_bg--mix-30: hsl(4, 33%, 30%);
  --m-bad_bg--mix-50: hsl(2, 44%, 36%);
  --m-bad_bg--mix-60: hsl(1, 48%, 39%);
  --m-bad_bg--mix-80: hsl(0, 55%, 44%);
  --m-bad_bg-zebra--mix-20: hsl(6, 26%, 28%);
  --m-bad_bg-zebra2--mix-20: hsl(4, 24%, 29%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 31%, 31%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 47%, 39%);
  --m-bad_clearer--mix-80: hsl(0, 68%, 50%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(37, 7%, 22%, 0.4);
  --m-bg--fade-20: hsla(37, 7%, 22%, 0.8);
  --m-bg--fade-30: hsla(37, 7%, 22%, 0.7);
  --m-bg--fade-50: hsla(37, 7%, 22%, 0.5);
  --m-bg-page_bg--mix-50: hsl(40, 8%, 15%);
  --m-bg-zebra2--fade-1: hsla(37, 5%, 23%, 0.99);
  --m-blunder_bg--mix-30: hsl(4, 28%, 33%);
  --m-blunder_font--mix-70: hsl(360, 42%, 55%);
  --m-body-gradient_bg-page--mix-50: hsl(39, 11%, 12%);
  --m-brag--alpha-70: hsla(37, 74%, 43%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 43%, 0.5);
  --m-brag_bg--mix-15: hsl(37, 24%, 25%);
  --m-brag_bg--mix-20: hsl(37, 29%, 26%);
  --m-brag_bg--mix-40: hsl(37, 45%, 30%);
  --m-brag_bg--mix-60: hsl(37, 57%, 35%);
  --m-brag_bg--mix-70: hsl(37, 62%, 37%);
  --m-brag_bg--mix-80: hsl(37, 66%, 39%);
  --m-brag_bg-page--mix-20: hsl(38, 47%, 15%);
  --m-brag_bg-page--mix-60: hsl(37, 67%, 29%);
  --m-brag_dark--mix-85: hsl(37, 68%, 40%);
  --m-brag_white--mix-80: hsl(37, 56%, 55%);
  --m-brilliant_bg--mix-30: hsl(122, 31%, 30%);
  --m-clas_bg--mix-10: hsl(277, 8%, 26%);
  --m-clas_bg--mix-30: hsl(259, 24%, 34%);
  --m-clas_bg--mix-7: hsl(295, 5%, 24%);
  --m-clas_bg--mix-80: hsl(255, 53%, 56%);
  --m-clas_bg-zebra--mix-20: hsl(263, 17%, 30%);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-dimmer_clearer--mix-63: hsl(0, 100%, 19%);
  --m-font--fade-50: hsla(220, 1%, 43%, 0.5);
  --m-font--fade-70: hsla(220, 1%, 43%, 0.3);
  --m-font--fade-83: hsla(220, 1%, 43%, 0.17);
  --m-font_bg--mix-20: hsl(37, 4%, 26%);
  --m-font_bg--mix-30: hsl(37, 3%, 28%);
  --m-font_clas--mix-40: hsl(254, 41%, 56%);
  --m-font_dimmer--mix-50: hsl(220, 1%, 22%);
  --m-font_dimmer--mix-85: hsl(220, 1%, 37%);
  --m-font_white--mix-20: hsl(220, 1%, 89%);
  --m-good_bg--mix-30: hsl(38, 7%, 15%);
  --m-inaccuracy_bg--mix-30: hsl(200, 23%, 34%);
  --m-inaccuracy_font--mix-70: hsl(202, 48%, 57%);
  --m-interesting_bg--mix-30: hsl(316, 21%, 37%);
  --m-mistake_bg--mix-30: hsl(41, 51%, 29%);
  --m-mistake_font--mix-70: hsl(41, 70%, 45%);
  --m-primary--alpha-70: hsla(202, 75%, 47%, 0.7);
  --m-primary--fade-50: hsla(202, 75%, 47%, 0.5);
  --m-primary--fade-80: hsla(202, 75%, 47%, 0.2);
  --m-primary--lighten-10: hsl(202, 75%, 57%);
  --m-primary--lighten-7: hsl(202, 75%, 54%);
  --m-primary_bg--mix-10: hsl(192, 9%, 24%);
  --m-primary_bg--mix-12: hsl(195, 11%, 25%);
  --m-primary_bg--mix-15: hsl(197, 15%, 26%);
  --m-primary_bg--mix-17: hsl(198, 18%, 26%);
  --m-primary_bg--mix-18: hsl(198, 19%, 26%);
  --m-primary_bg--mix-20: hsl(199, 22%, 27%);
  --m-primary_bg--mix-25: hsl(200, 27%, 28%);
  --m-primary_bg--mix-30: hsl(200, 32%, 29%);
  --m-primary_bg--mix-35: hsl(201, 37%, 31%);
  --m-primary_bg--mix-40: hsl(201, 41%, 32%);
  --m-primary_bg--mix-50: hsl(201, 49%, 35%);
  --m-primary_bg--mix-60: hsl(202, 56%, 37%);
  --m-primary_bg--mix-70: hsl(202, 61%, 40%);
  --m-primary_bg--mix-75: hsl(202, 64%, 41%);
  --m-primary_bg--mix-80: hsl(202, 66%, 42%);
  --m-primary_bg-page--mix-15: hsl(199, 34%, 14%);
  --m-primary_bg-page--mix-20: hsl(200, 41%, 16%);
  --m-primary_bg-page--mix-60: hsl(202, 67%, 31%);
  --m-primary_bg-zebra--mix-10: hsl(192, 9%, 24%);
  --m-primary_bg-zebra2--mix-20: hsl(201, 22%, 28%);
  --m-primary_black--mix-70: hsl(202, 75%, 33%);
  --m-primary_black--mix-80: hsl(202, 75%, 38%);
  --m-primary_dark--mix-80: hsl(202, 68%, 42%);
  --m-primary_font--mix-35: hsl(203, 29%, 45%);
  --m-primary_font--mix-50: hsl(202, 40%, 45%);
  --m-primary_font--mix-90: hsl(202, 68%, 47%);
  --m-primary_white--mix-40: hsl(202, 67%, 79%);
  --m-primary_white--mix-60: hsl(202, 67%, 68%);
  --m-primary_white--mix-80: hsl(202, 67%, 58%);
  --m-secondary--fade-30: hsla(0, 0%, 36%, 0.7);
  --m-secondary--fade-40: hsla(0, 0%, 36%, 0.6);
  --m-secondary--fade-50: hsla(0, 0%, 36%, 0.5);
  --m-secondary--fade-60: hsla(0, 0%, 36%, 0.4);
  --m-secondary--fade-80: hsla(0, 0%, 36%, 0.2);
  --m-secondary--lighten-4: hsl(0, 0%, 40%);
  --m-secondary_bg--mix-10: hsl(37, 6%, 23%);
  --m-secondary_bg--mix-12: hsl(38, 6%, 24%);
  --m-secondary_bg--mix-15: hsl(38, 6%, 24%);
  --m-secondary_bg--mix-20: hsl(37, 5%, 25%);
  --m-secondary_bg--mix-25: hsl(38, 5%, 25%);
  --m-secondary_bg--mix-30: hsl(38, 4%, 26%);
  --m-secondary_bg--mix-35: hsl(37, 4%, 27%);
  --m-secondary_bg--mix-40: hsl(38, 3%, 28%);
  --m-secondary_bg--mix-50: hsl(38, 3%, 29%);
  --m-secondary_bg--mix-70: hsl(37, 1%, 32%);
  --m-secondary_bg--mix-75: hsl(38, 1%, 33%);
  --m-secondary_bg--mix-80: hsl(38, 1%, 33%);
  --m-secondary_bg-page--mix-50: hsl(45, 2%, 22%);
  --m-secondary_bg-popup--mix-10: hsl(37, 6%, 23%);
  --m-secondary_bg-popup--mix-27: hsl(38, 4%, 26%);
  --m-secondary_bg-popup--mix-36: hsl(37, 4%, 27%);
  --m-secondary_bg-zebra--mix-12: hsl(38, 6%, 24%);
  --m-secondary_bg-zebra--mix-20: hsl(37, 5%, 25%);
  --m-secondary_dark--mix-50: hsl(0, 0%, 28%);
  --m-secondary_dark--mix-85: hsl(0, 0%, 34%);
  --m-secondary_dimmer--mix-82: hsl(0, 0%, 30%);
  --m-secondary_white--mix-80: hsl(0, 0%, 49%);
  --m-yellow_bg--mix-30: hsl(58, 53%, 30%);
}

@mixin light-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsl(22, 72%, 94%);
  --m-accent_bg--mix-12: hsl(22, 72%, 93%);
  --m-accent_bg--mix-15: hsl(22, 72%, 91%);
  --m-accent_bg--mix-20: hsl(22, 72%, 88%);
  --m-accent_bg--mix-60: hsl(22, 72%, 65%);
  --m-accent_bg--mix-70: hsl(22, 72%, 59%);
  --m-accent_bg-popup--mix-10: hsl(22, 72%, 94%);
  --m-accent_bg-zebra--mix-10: hsl(23, 51%, 91%);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsl(0, 60%, 95%);
  --m-bad_bg--mix-15: hsl(0, 60%, 93%);
  --m-bad_bg--mix-20: hsl(0, 60%, 90%);
  --m-bad_bg--mix-25: hsl(0, 60%, 88%);
  --m-bad_bg--mix-30: hsl(0, 60%, 85%);
  --m-bad_bg--mix-50: hsl(0, 60%, 75%);
  --m-bad_bg--mix-60: hsl(0, 60%, 70%);
  --m-bad_bg--mix-80: hsl(0, 60%, 60%);
  --m-bad_bg-zebra--mix-20: hsl(1, 49%, 87%);
  --m-bad_bg-zebra2--mix-20: hsl(4, 41%, 84%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 47%, 79%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 55%, 67%);
  --m-bad_clearer--mix-80: hsl(0, 60%, 40%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(0, 0%, 100%, 0.4);
  --m-bg--fade-20: hsla(0, 0%, 100%, 0.8);
  --m-bg--fade-30: hsla(0, 0%, 100%, 0.7);
  --m-bg--fade-50: hsla(0, 0%, 100%, 0.5);
  --m-bg-page_bg--mix-50: hsl(216, 65%, 98%);
  --m-bg-zebra2--fade-1: hsla(37, 12%, 92%, 0.99);
  --m-blunder_bg--mix-30: hsl(0, 68%, 85%);
  --m-blunder_font--mix-70: hsl(0, 54%, 44%);
  --m-body-gradient_bg-page--mix-50: hsl(216, 14%, 90%);
  --m-brag--alpha-70: hsla(37, 74%, 48%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 48%, 0.5);
  --m-brag_bg--mix-15: hsl(37, 68%, 92%);
  --m-brag_bg--mix-20: hsl(37, 68%, 90%);
  --m-brag_bg--mix-40: hsl(37, 68%, 79%);
  --m-brag_bg--mix-60: hsl(37, 68%, 69%);
  --m-brag_bg--mix-70: hsl(37, 68%, 64%);
  --m-brag_bg--mix-80: hsl(37, 68%, 58%);
  --m-brag_bg-page--mix-20: hsl(38, 34%, 86%);
  --m-brag_bg-page--mix-60: hsl(37, 61%, 67%);
  --m-brag_dark--mix-85: hsl(37, 69%, 44%);
  --m-brag_white--mix-80: hsl(37, 68%, 58%);
  --m-brilliant_bg--mix-30: hsl(129, 31%, 79%);
  --m-clas_bg--mix-10: hsl(255, 83%, 96%);
  --m-clas_bg--mix-30: hsl(255, 83%, 89%);
  --m-clas_bg--mix-7: hsl(255, 83%, 98%);
  --m-clas_bg--mix-80: hsl(255, 83%, 72%);
  --m-clas_bg-zebra--mix-20: hsl(257, 57%, 90%);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-dimmer_clearer--mix-63: hsl(0, 0%, 63%);
  --m-font--fade-50: hsla(0, 0%, 30%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 30%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 30%, 0.17);
  --m-font_bg--mix-20: hsl(0, 0%, 86%);
  --m-font_bg--mix-30: hsl(0, 0%, 79%);
  --m-font_clas--mix-40: hsl(255, 36%, 51%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 65%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 41%);
  --m-font_white--mix-20: hsl(0, 0%, 86%);
  --m-good_bg--mix-30: hsl(0, 0%, 70%);
  --m-inaccuracy_bg--mix-30: hsl(202, 52%, 82%);
  --m-inaccuracy_font--mix-70: hsl(202, 59%, 37%);
  --m-interesting_bg--mix-30: hsl(307, 80%, 88%);
  --m-mistake_bg--mix-30: hsl(41, 54%, 81%);
  --m-mistake_font--mix-70: hsl(41, 73%, 34%);
  --m-primary--alpha-70: hsla(209, 90%, 46%, 0.7);
  --m-primary--fade-50: hsla(209, 90%, 46%, 0.5);
  --m-primary--fade-80: hsla(209, 90%, 46%, 0.2);
  --m-primary--lighten-10: hsl(209, 90%, 56%);
  --m-primary--lighten-7: hsl(209, 90%, 53%);
  --m-primary_bg--mix-10: hsl(209, 77%, 95%);
  --m-primary_bg--mix-12: hsl(209, 77%, 94%);
  --m-primary_bg--mix-15: hsl(209, 77%, 92%);
  --m-primary_bg--mix-17: hsl(209, 77%, 91%);
  --m-primary_bg--mix-18: hsl(209, 77%, 90%);
  --m-primary_bg--mix-20: hsl(209, 77%, 89%);
  --m-primary_bg--mix-25: hsl(209, 77%, 87%);
  --m-primary_bg--mix-30: hsl(209, 77%, 84%);
  --m-primary_bg--mix-35: hsl(209, 77%, 81%);
  --m-primary_bg--mix-40: hsl(209, 77%, 78%);
  --m-primary_bg--mix-50: hsl(209, 77%, 73%);
  --m-primary_bg--mix-60: hsl(209, 77%, 68%);
  --m-primary_bg--mix-70: hsl(209, 77%, 62%);
  --m-primary_bg--mix-75: hsl(209, 77%, 60%);
  --m-primary_bg--mix-80: hsl(209, 77%, 57%);
  --m-primary_bg-page--mix-15: hsl(211, 73%, 88%);
  --m-primary_bg-page--mix-20: hsl(211, 74%, 86%);
  --m-primary_bg-page--mix-60: hsl(209, 76%, 66%);
  --m-primary_bg-zebra--mix-10: hsl(209, 44%, 91%);
  --m-primary_bg-zebra2--mix-20: hsl(208, 44%, 83%);
  --m-primary_black--mix-70: hsl(209, 90%, 32%);
  --m-primary_black--mix-80: hsl(209, 90%, 37%);
  --m-primary_dark--mix-80: hsl(209, 81%, 41%);
  --m-primary_font--mix-35: hsl(209, 40%, 36%);
  --m-primary_font--mix-50: hsl(209, 54%, 38%);
  --m-primary_font--mix-90: hsl(209, 84%, 44%);
  --m-primary_white--mix-40: hsl(209, 77%, 78%);
  --m-primary_white--mix-60: hsl(209, 77%, 68%);
  --m-primary_white--mix-80: hsl(209, 77%, 57%);
  --m-secondary--fade-30: hsla(209, 90%, 60%, 0.7);
  --m-secondary--fade-40: hsla(209, 90%, 60%, 0.6);
  --m-secondary--fade-50: hsla(209, 90%, 60%, 0.5);
  --m-secondary--fade-60: hsla(209, 90%, 60%, 0.4);
  --m-secondary--fade-80: hsla(209, 90%, 60%, 0.2);
  --m-secondary--lighten-4: hsl(209, 90%, 64%);
  --m-secondary_bg--mix-10: hsl(209, 90%, 96%);
  --m-secondary_bg--mix-12: hsl(209, 90%, 95%);
  --m-secondary_bg--mix-15: hsl(209, 90%, 94%);
  --m-secondary_bg--mix-20: hsl(209, 90%, 92%);
  --m-secondary_bg--mix-25: hsl(209, 90%, 90%);
  --m-secondary_bg--mix-30: hsl(209, 90%, 88%);
  --m-secondary_bg--mix-35: hsl(209, 90%, 86%);
  --m-secondary_bg--mix-40: hsl(209, 90%, 84%);
  --m-secondary_bg--mix-50: hsl(209, 90%, 80%);
  --m-secondary_bg--mix-70: hsl(209, 90%, 72%);
  --m-secondary_bg--mix-75: hsl(209, 90%, 70%);
  --m-secondary_bg--mix-80: hsl(209, 90%, 68%);
  --m-secondary_bg-page--mix-50: hsl(210, 88%, 78%);
  --m-secondary_bg-popup--mix-10: hsl(209, 90%, 96%);
  --m-secondary_bg-popup--mix-27: hsl(209, 90%, 89%);
  --m-secondary_bg-popup--mix-36: hsl(209, 90%, 86%);
  --m-secondary_bg-zebra--mix-12: hsl(209, 50%, 92%);
  --m-secondary_bg-zebra--mix-20: hsl(209, 64%, 89%);
  --m-secondary_dark--mix-50: hsl(209, 45%, 40%);
  --m-secondary_dark--mix-85: hsl(209, 67%, 54%);
  --m-secondary_dimmer--mix-82: hsl(209, 90%, 67%);
  --m-secondary_white--mix-80: hsl(209, 90%, 68%);
  --m-yellow_bg--mix-30: hsl(60, 100%, 85%);
}

@mixin transp-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsla(22, 100%, 4%, 0.64);
  --m-accent_bg--mix-12: hsla(22, 100%, 5%, 0.65);
  --m-accent_bg--mix-15: hsla(22, 100%, 6%, 0.66);
  --m-accent_bg--mix-20: hsla(22, 100%, 8%, 0.68);
  --m-accent_bg--mix-60: hsla(22, 100%, 25%, 0.84);
  --m-accent_bg--mix-70: hsla(22, 100%, 29%, 0.88);
  --m-accent_bg-popup--mix-10: hsla(22, 100%, 4%, 0.64);
  --m-accent_bg-zebra--mix-10: hsla(22, 72%, 94%, 0.15);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsla(0, 60%, 5%, 0.64);
  --m-bad_bg--mix-15: hsla(0, 60%, 8%, 0.66);
  --m-bad_bg--mix-20: hsla(0, 60%, 10%, 0.68);
  --m-bad_bg--mix-25: hsla(0, 60%, 13%, 0.7);
  --m-bad_bg--mix-30: hsla(0, 60%, 15%, 0.72);
  --m-bad_bg--mix-50: hsla(0, 60%, 25%, 0.8);
  --m-bad_bg--mix-60: hsla(0, 60%, 30%, 0.84);
  --m-bad_bg--mix-80: hsla(0, 60%, 40%, 0.92);
  --m-bad_bg-zebra--mix-20: hsla(0, 60%, 90%, 0.24);
  --m-bad_bg-zebra2--mix-20: hsl(4, 24%, 29%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 31%, 31%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 47%, 39%);
  --m-bad_clearer--mix-80: hsl(0, 68%, 50%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(0, 0%, 0%, 0.4);
  --m-bg--fade-20: hsla(0, 0%, 0%, 0.48);
  --m-bg--fade-30: hsla(0, 0%, 0%, 0.42);
  --m-bg--fade-50: hsla(0, 0%, 0%, 0.3);
  --m-bg-page_bg--mix-50: hsla(45, 10%, 4%, 0.8);
  --m-bg-zebra2--fade-1: hsla(37, 5%, 23%, 0.99);
  --m-blunder_bg--mix-30: hsla(0, 46%, 18%, 0.72);
  --m-blunder_font--mix-70: hsl(0, 57%, 66%);
  --m-body-gradient_bg-page--mix-50: hsl(39, 11%, 12%);
  --m-brag--alpha-70: hsla(37, 74%, 43%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 43%, 0.5);
  --m-brag_bg--mix-15: hsla(37, 74%, 6%, 0.66);
  --m-brag_bg--mix-20: hsla(37, 74%, 9%, 0.68);
  --m-brag_bg--mix-40: hsla(37, 74%, 17%, 0.76);
  --m-brag_bg--mix-60: hsla(37, 74%, 26%, 0.84);
  --m-brag_bg--mix-70: hsla(37, 74%, 30%, 0.88);
  --m-brag_bg--mix-80: hsla(37, 74%, 35%, 0.92);
  --m-brag_bg-page--mix-20: hsl(38, 47%, 15%);
  --m-brag_bg-page--mix-60: hsl(37, 67%, 29%);
  --m-brag_dark--mix-85: hsl(37, 68%, 40%);
  --m-brag_white--mix-80: hsl(37, 56%, 55%);
  --m-brilliant_bg--mix-30: hsla(129, 71%, 13%, 0.72);
  --m-clas_bg--mix-10: hsla(255, 45%, 6%, 0.64);
  --m-clas_bg--mix-30: hsla(255, 45%, 19%, 0.72);
  --m-clas_bg--mix-7: hsla(255, 45%, 5%, 0.63);
  --m-clas_bg--mix-80: hsla(255, 49%, 52%, 0.92);
  --m-clas_bg-zebra--mix-20: hsla(255, 83%, 93%, 0.24);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-dimmer_clearer--mix-63: hsl(0, 100%, 19%);
  --m-font--fade-50: hsla(0, 0%, 80%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 80%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 80%, 0.17);
  --m-font_bg--mix-20: hsla(0, 0%, 16%, 0.68);
  --m-font_bg--mix-30: hsla(0, 0%, 24%, 0.72);
  --m-font_clas--mix-40: hsl(255, 60%, 71%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 40%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 68%);
  --m-font_white--mix-20: hsl(0, 0%, 96%);
  --m-good_bg--mix-30: hsla(0, 0%, 0%, 0.72);
  --m-inaccuracy_bg--mix-30: hsla(202, 48%, 19%, 0.72);
  --m-inaccuracy_font--mix-70: hsl(202, 64%, 68%);
  --m-interesting_bg--mix-30: hsla(307, 34%, 21%, 0.72);
  --m-mistake_bg--mix-30: hsla(41, 100%, 14%, 0.72);
  --m-mistake_font--mix-70: hsl(41, 71%, 56%);
  --m-primary--alpha-70: hsla(202, 75%, 47%, 0.7);
  --m-primary--fade-50: hsla(202, 75%, 47%, 0.5);
  --m-primary--fade-80: hsla(202, 75%, 47%, 0.2);
  --m-primary--lighten-10: hsl(202, 75%, 57%);
  --m-primary--lighten-7: hsl(202, 75%, 54%);
  --m-primary_bg--mix-10: hsla(202, 75%, 5%, 0.64);
  --m-primary_bg--mix-12: hsla(202, 75%, 6%, 0.65);
  --m-primary_bg--mix-15: hsla(202, 75%, 7%, 0.66);
  --m-primary_bg--mix-17: hsla(202, 75%, 8%, 0.67);
  --m-primary_bg--mix-18: hsla(202, 75%, 8%, 0.67);
  --m-primary_bg--mix-20: hsla(202, 75%, 9%, 0.68);
  --m-primary_bg--mix-25: hsla(202, 75%, 12%, 0.7);
  --m-primary_bg--mix-30: hsla(202, 75%, 14%, 0.72);
  --m-primary_bg--mix-35: hsla(202, 75%, 16%, 0.74);
  --m-primary_bg--mix-40: hsla(202, 75%, 19%, 0.76);
  --m-primary_bg--mix-50: hsla(202, 75%, 24%, 0.8);
  --m-primary_bg--mix-60: hsla(202, 75%, 28%, 0.84);
  --m-primary_bg--mix-70: hsla(202, 75%, 33%, 0.88);
  --m-primary_bg--mix-75: hsla(202, 75%, 35%, 0.9);
  --m-primary_bg--mix-80: hsla(202, 75%, 38%, 0.92);
  --m-primary_bg-page--mix-15: hsl(199, 34%, 14%);
  --m-primary_bg-page--mix-20: hsl(200, 41%, 16%);
  --m-primary_bg-page--mix-60: hsl(202, 67%, 31%);
  --m-primary_bg-zebra--mix-10: hsla(202, 67%, 95%, 0.15);
  --m-primary_bg-zebra2--mix-20: hsl(201, 22%, 28%);
  --m-primary_black--mix-70: hsl(202, 75%, 33%);
  --m-primary_black--mix-80: hsl(202, 75%, 38%);
  --m-primary_dark--mix-80: hsl(202, 68%, 42%);
  --m-primary_font--mix-35: hsl(202, 39%, 68%);
  --m-primary_font--mix-50: hsl(202, 48%, 64%);
  --m-primary_font--mix-90: hsl(202, 64%, 50%);
  --m-primary_white--mix-40: hsl(202, 67%, 79%);
  --m-primary_white--mix-60: hsl(202, 67%, 68%);
  --m-primary_white--mix-80: hsl(202, 67%, 58%);
  --m-secondary--fade-30: hsla(0, 0%, 36%, 0.7);
  --m-secondary--fade-40: hsla(0, 0%, 36%, 0.6);
  --m-secondary--fade-50: hsla(0, 0%, 36%, 0.5);
  --m-secondary--fade-60: hsla(0, 0%, 36%, 0.4);
  --m-secondary--fade-80: hsla(0, 0%, 36%, 0.2);
  --m-secondary--lighten-4: hsl(0, 0%, 40%);
  --m-secondary_bg--mix-10: hsla(0, 0%, 4%, 0.64);
  --m-secondary_bg--mix-12: hsla(0, 0%, 4%, 0.65);
  --m-secondary_bg--mix-15: hsla(0, 0%, 5%, 0.66);
  --m-secondary_bg--mix-20: hsla(0, 0%, 7%, 0.68);
  --m-secondary_bg--mix-25: hsla(0, 0%, 9%, 0.7);
  --m-secondary_bg--mix-30: hsla(0, 0%, 11%, 0.72);
  --m-secondary_bg--mix-35: hsla(0, 0%, 13%, 0.74);
  --m-secondary_bg--mix-40: hsla(0, 0%, 14%, 0.76);
  --m-secondary_bg--mix-50: hsla(0, 0%, 18%, 0.8);
  --m-secondary_bg--mix-70: hsla(0, 0%, 25%, 0.88);
  --m-secondary_bg--mix-75: hsla(0, 0%, 27%, 0.9);
  --m-secondary_bg--mix-80: hsla(0, 0%, 29%, 0.92);
  --m-secondary_bg-page--mix-50: hsl(45, 2%, 22%);
  --m-secondary_bg-popup--mix-10: hsla(0, 0%, 4%, 0.64);
  --m-secondary_bg-popup--mix-27: hsla(0, 0%, 10%, 0.71);
  --m-secondary_bg-popup--mix-36: hsla(0, 0%, 13%, 0.74);
  --m-secondary_bg-zebra--mix-12: hsla(0, 0%, 92%, 0.16);
  --m-secondary_bg-zebra--mix-20: hsla(0, 0%, 87%, 0.24);
  --m-secondary_dark--mix-50: hsl(0, 0%, 28%);
  --m-secondary_dark--mix-85: hsl(0, 0%, 34%);
  --m-secondary_dimmer--mix-82: hsl(0, 0%, 30%);
  --m-secondary_white--mix-80: hsl(0, 0%, 49%);
  --m-yellow_bg--mix-30: hsla(60, 100%, 15%, 0.72);
}

