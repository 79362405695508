@import 'default';

// this may not import anything from outside common/css/colors

$c-font: hsl(0, 0%, 80%);
$c-bg: hsla(0, 0%, 0%, 0.6); // alias for $c-bg-high
$c-bg-low: #fafaf9;
$c-bg-zebra: hsla(0, 0%, 100%, 0.05);
$c-bg-popup: hsla(0, 0%, 0%, 0.6);

html.transp {
  @include shared-color-defs;

  --c-bg-input: #{$c-bg-low};
  --c-bg-variation: hsla(0, 0%, 0%, 0.25);
  --c-bg-header-dropdown: #ffffff;
  --c-metal-top: hsla(0, 0%, 0%, 0.4);
  --c-metal-bottom: hsla(0, 0%, 0%, 0.5);
  --c-metal-top-hover: #3b81c2;
  --c-metal-bottom-hover: #3b81c2;

  --c-font-dim: #{change-color($c-font, $lightness: 69%)};
  --c-font-dimmer: #{change-color($c-font, $lightness: 48%)};
  --c-font-clear: #{change-color($c-font, $lightness: 89%)};
  --c-font-clearer: #{change-color($c-font, $lightness: 97%)};
  --c-font-page: #{change-color($c-font, $lightness: 97%)};

  @include transp-mix;
}
